@import url('https://fonts.googleapis.com/css2?family=Overpass:wght@400;500;700;900&display=swap');

html,
body {
  padding: 0;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
  background: #fff;
  font-display: optional
}

body {
  font-family: 'Overpass', sans-serif;
  font-weight: 700;
  font-size: 16px;
  letter-spacing: 0.1em;
  padding-top: 160px;
  scroll-padding-top: 160px;
  transition: all 600ms;
  color: #000;
}

a {
  color: inherit;
  text-decoration: none;
  transition: all 500ms ease;
  outline: 0 none;

  &:visited {
    color:#000;
  }

  &:hover {
    text-decoration: underline;
  }
}



* {
  box-sizing: border-box;
}

.weight-500 {
  font-weight: 500;
}

.weight-700 {
  font-weight: 700;
}

.weight-400 {
  font-weight: 400;
}

.weight-900 {
  font-weight: 900;
}

.transition-all {
  transition: all 500ms ease;
}

.section {
  padding: 40px 0;
  
  &.grid {
    padding-left: 20px;
    padding-right: 20px;
    margin-bottom: 40px;
  }
  
  &.full-view {
    min-height: 100vh;
  }
}



.hidden-mobile {
  display: none;
}


.page {
  &_title {
    font-size: 50px;
    text-transform: uppercase;
    letter-spacing: 0.1em;
  }
}

.hide {
  transform: translate(0, 100px);
  opacity: 0;
}

.show {
  transform: translate(0, 0);
  opacity: 1;
}

body {
  &.is-sticky {
    #header {
      background-color: transparent;
    }
  }
}


@media screen and (min-width: 1024px) {
  body {
    padding-top: 180px;
    scroll-padding-top: 180px;
  }

  .hidden-mobile {
    display: block;
  }

  * {
    cursor: none
  }
}

@media screen and (min-width: 1366px) {
  body {
    padding-top: 190px;
    scroll-padding-top: 190px;
  }

  .section.spacing {
    margin-bottom: 10.25vw;
  }

  .page {
    &_title {
      font-size: 100px;
      font-size: 6.94vw;
    }
  }
}
